<template>
  <div>
    <div style="height: 40px;text-align: center;line-height: 40px;"> {{mytitle}}</div>
    <div :id="'player_'+myvalue"
      style=" height: 200px;width: 100%;position: static; background-color: #faebd742;margin-bottom: 51px; "></div>
  </div>
</template>
<script>
import { educourse_sign_phone } from "@/api/index";
export default {
  props: {
    myvalue: {
      default: ''
    },
    mytitle: {
      default: ''
    },
    mydata: {
      default: () => { }
    },
  },
  data() {
    return {
      vodPlayerJs: '//player.polyv.net/script/player.js',
      vid: this.myvalue,
      isShow: false,
      mytype: {},
      timer: null,
      spall: 0,
    };
  },
  mounted() {
    //this.isshow = true;
    if (this.vid != '') {
      console.log("mounted", this.vid);
      this.loadPlayerScript(this.jn_load_sign);
    }
  },
  methods: {
    loadPlayerScript(callback) {
      // if (!window.polyvPlayer) {
      //   const myScript = document.createElement('script');
      //   myScript.setAttribute('src', this.vodPlayerJs);
      //   myScript.onload = callback;
      //   document.body.appendChild(myScript);
      // } else {
      //   callback();
      // }
      callback();
    },
    loadPlayer(psign, pts) {
      this.isShow = true;
      const polyvPlayer = window.polyvPlayer;
      this.player = polyvPlayer({
        wrap: '#player_' + this.myvalue,
        width: '100%',
        height: '240px',
        //volume: 0.75,
        vid: this.vid,
        autoplay: 'true',
        loop: true,
        df: 3,
        cover_display: 'scaleAspectFill',
        ban_ui: 'off',
        skinLocation: 1,
        sign: psign,
        ts: pts
      });

      this.player.on('s2j_onPlayStart', (...params) => {
        console.log('s2j_onPlayStart', params);
      });

      this.player.on('s2j_onPlayOver', (...params) => {
        console.log('s2j_onPlayOver', params);
      });

      this.player.on('s2j_onPlayerError', (...params) => {
        console.log('s2j_onPlayerError', params);
      });

    },
    jn_load_sign() {
      educourse_sign_phone(this.vid).then(response => {
        // console.log("getcourse_sign=" + JSON.stringify(response.data));
        // console.log("getcourse_sign=" + JSON.stringify(response.data.data));
        // console.log("getcourse_sign=" + JSON.stringify(response.data.data.token));
        console.log("getcourse_sign333333=", response.data);
        var psign = response.data.mydata;
        var pts = response.data.mydata_sub;
        this.loadPlayer(psign, pts);
      });
    },
  },
  destroyed() {
    if (this.player) {
      this.player.destroy();
    }
    //clearInterval(this.timer);
  }
};
</script>