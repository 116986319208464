<template>
  <div>
    <van-nav-bar title="不在线学校食堂曝光台" left-text="返回" left-arrow fixed placeholder @click-left="closeasd()">
    </van-nav-bar>
    <div style="text-align: -webkit-center;margin-top: 10px;">统计时间：{{ mydate }}</div>
    <van-divider content-position="left"
      :style="{color:'black',  borderColor: 'black', padding: '0 16px' }">地区不在线合计</van-divider>
    <table class="mtttline">
      <tr style="background-color: rgb(241, 241, 241);">
        <td rowspan="2">地区</td>
        <td colspan="2">学校食堂</td>
        <td colspan="2">摄像头</td>
      </tr>
      <tr style="background-color: rgb(241, 241, 241);">
        <td>不在线数量</td>
        <td>不在线率</td>
        <td>不在线数量</td>
        <td>不在线率</td>
      </tr>
      <tr v-for="item in mydata2" :key="item">
        <td style="color: #399bff;text-decoration: underline #399bff;" @click="getaaaa(item.re_city)">{{item.re_city}}
        </td>
        <td>{{item.m_count}}</td>
        <td>{{item.m_zxlno}}</td>
        <td>{{item.sub_m_count}}</td>
        <td>{{item.sub_m_zxlno}}</td>
      </tr>
    </table>

    <div v-if="mydata_yy && mydata_yy.content" style="padding: 10px;line-height: 2;">
      <div v-html="mydata_yy.content"> </div>
    </div>

    <van-divider content-position="left" :style="{color:'black',  borderColor: 'black', padding: '0 16px' }">{{ mycc }}
      &nbsp;不在线学校食堂 -明细列表</van-divider>

    <table class="mtttline">
      <tr style="background-color: rgb(241, 241, 241);">
        <td style="width:40px;">序号</td>
        <td>学校名称</td>
        <td style="width:60px;">不在线<br>天数</td>
        <td style="width:60px;">不在线<br>摄像头<br>数量</td>
      </tr>
      <tr v-for="(item,index) in mydata" :key="item">
        <td style="width:20px;">{{index+1}}</td>
        <td style="text-align: -webkit-left;">&nbsp;{{item.re_name}}</td>
        <td>{{item.rec_day}}</td>
        <td>{{item.nonn}}</td>
      </tr>
    </table>
    <div style="height:100px"></div>
  </div>
</template>
<script>
import { mypub } from "@/api/index";
export default {
  data() {
    return {
      mydata: [],
      mydata2: [],
      mydata_yy: {},
      sdata: { page: 1 },
      mycc: '',
      mydate: '',
    };
  },
  created() {
    this.load_api('');
    this.load_yy();
  },
  methods: {
    closeasd() {
      this.$emit('on_myclose', true);
    },
    getaaaa(city) {
      console.log(city);
      if (city == '合计') {
        city = '';
      }
      this.load_api(city);
    },
    load_api(city) {
      var dd = { city: city }
      this.mycc = city;
      mypub('scjg/com_noline_day', '', dd).then(response => {
        // console.log("com_noline_day=", response.data.mydata);
        console.log("com_noline_day=", response.data.mydata_sub);
        this.mydata = response.data.mydata;
        if (this.mydata && this.mydata.length > 0) {
          this.mydate = this.mydata[0].rec_now;
        }
        if (city == '') {
          this.mydata2 = response.data.mydata_sub;
        }
      });


    },
    load_yy() {
      mypub('getccconfig_json', '', { info: "mclz_outinfo", }).then(response => {
        // console.log("com_noline_day=", response.data.mydata);
        this.mydata_yy = response.data.mydata;
        this.mydata_yy.content =
          this.mydata_yy.content
            .replaceAll("&nbsp;", "")
          ;
      });
    },

  },
};
</script>
<style >
.mtttline {
  font-size: 10px;
  text-align: center;
  width: 100%;
}

.mtttline {
  table-layout: fixed;
  empty-cells: show;
  border-collapse: collapse;
  margin: 0 auto;
  border: 0px solid rgb(204, 204, 204);
  font-size: 10px;
  width: 98%;
}

.mtttline td {
  height: 24px;
  border: 1px solid rgb(204, 204, 204);
  padding: 0 2px 0;
  text-align: center;
}
</style>