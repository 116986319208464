<template>
  <div>

    <van-popup v-model:show="isshow" closeable close-icon="close" position="bottom" :style="{ height: '100%' }">
      <!-- {{mydata}} -->
      <div style="margin-top: 40px;">&nbsp;</div>
      <cp_ship v-if="isshow" :myvalue="mydata.vid" :mytitle="mydata.title" />

    </van-popup>

  </div>
</template>
<script>
import cp_ship from "@/components/cp_ship";
export default {
  components: {
    cp_ship,
  },
  props: {
    myvalue: {
      default: ''
    },
    mytitle: {
      default: ''
    },
    mydata: {
      default: () => { }
    },
  },
  name: "cp_myc_showsp",
  data() {
    return {
      isshow: false,
    };
  },
  created() {
    this.load_api();
  },
  methods: {
    show() {
      console.log("show");
      this.isshow = true;
    },
    load_api() {
      console.log("load_api");
    },
  },
};
</script>
<style >
</style>