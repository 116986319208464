<template>
  <div>

    <van-popup v-model:show="isshow" position="bottom" :style="{ height: '100%' }">
      <!-- {{mydata}} -->
      <cp_myc_showbgt_sub v-if="isshow" @on_myclose="on_myclose" />

    </van-popup>

  </div>
</template>
<script>
import cp_myc_showbgt_sub from "@/components/cp_myc_showbgt_sub";
export default {
  components: {
    cp_myc_showbgt_sub,
  },
  props: {
    myvalue: {
      default: ''
    },
    mytitle: {
      default: ''
    },
    mydata: {
      default: () => { }
    },
  },
  name: "cp_myc_showsp",
  data() {
    return {
      isshow: false,
    };
  },
  created() {
    this.load_api();
  },
  methods: {
    on_myclose() {
      this.isshow = false;
    },
    show() {
      console.log("show");
      this.isshow = true;
    },
    load_api() {
      console.log("load_api");
    },
  },
};
</script>
<style >
</style>