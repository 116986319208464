<template>
  <div>

    <div v-if="mydata.isok">
      <div class="hu_jingzho_img" @click="this.$refs.my_cp_myc_showsp.show()">
        <img :src="mydata.url" :style="mydata.style">

        <!-- <img src="@/assets/images/ximg/bc_header.jpg" alt="" srcset=""> -->
      </div>
      <cp_myc_showsp ref="my_cp_myc_showsp" :mydata="mydata" />
    </div>

    <div v-if="mydata2.isok">
      <div class="hu_jingzho_img" @click="this.$refs.my_cp_myc_showbgt.show()">
        <img :src="mydata2.url" :style="mydata2.style">
        <!-- <img src="@/assets/images/ximg/bc_header.jpg" alt="" srcset=""> -->
      </div>
      <cp_myc_showbgt ref="my_cp_myc_showbgt" />
    </div>

  </div>
</template>
<script>
import Vue from 'vue'
import { mypub } from "@/api/index";
import cp_myc_showsp from './cp_myc_showsp.vue'
import cp_myc_showbgt from './cp_myc_showbgt.vue'
export default {
  components: {
    cp_myc_showsp,
    cp_myc_showbgt,
  },
  data() {
    return {
      mydata: '',
      mydata2: '',
      isok: false,
    };
  },
  created() {
    this.load_api();
    this.load_api2();
  },
  methods: {
    test() {
      // console.log(" refs", this.$refs);
      // console.log(" refs", this.$refs.my_cp_myc_showsp);
      // console.log(" refs", this.$refs.my_cp_myc_showsp.load_api());
      this.$refs.my_cp_myc_showsp.show();
    },
    load_api() {
      mypub('getccconfig_json', '', { info: 'app_pub_sp' }).then(response => {
        this.mydata = response.data.mydata;
        this.isok = true;
      });
    },
    load_api2() {
      mypub('getccconfig_json', '', { info: 'app_pub_bgt' }).then(response => {
        this.mydata2 = response.data.mydata;
        this.isok = true;
      });
    },
  },
};
</script>
<style></style>